import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormDomainFeaturesComponent } from '@monsido/forms/domain/steps/features/features.component';
import { FormsBuilderModule } from '@monsido/angular-shared-components/dist/angular-shared-components';
import { PipesModule } from '@monsido/core/pipes/pipes.module';
import { TranslateModule } from 'app/modules/translate.module';

@NgModule({
    declarations: [FormDomainFeaturesComponent],
    imports: [CommonModule, TranslateModule, FormsBuilderModule, PipesModule],
    exports: [FormDomainFeaturesComponent],
})
export class FeaturesModule {}
